require('./bootstrap');

require('alpinejs');

var header = document.querySelector("form h3.msk-purple");
var checkpoint = document.getElementById("checkpoint");
document.addEventListener("scroll", function(e){
    headerTop = header.getBoundingClientRect().bottom;
    //console.log(header.getBoundingClientRect())
    var scrolled = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
    if(scrolled > headerTop){
        checkpoint.classList.add("top-0");
    }else{
        checkpoint.classList.remove("top-0");
    }
})
